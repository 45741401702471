import StarTutoringZookeeper from 'components/pages/StarTutoring/pages/StarTutoringZookeeper'
import { PageProps } from 'gatsby'
import React from 'react'
import Layout from '../../../layouts/layout'

export default (props: PageProps) => {
  if (typeof window === "undefined") return null
  return <Layout>
    <StarTutoringZookeeper {...props} username={props.params.username} />
  </Layout>
}
